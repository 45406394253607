import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import FormattedDateTime from '../../components/FormattedDateTime';
import { AdministratorCommentAction } from '../../graphql/graphql-typings';
import type { AdministratorCommentFragment } from '../../graphql/graphql-typings';

interface Props {
    comments: AdministratorCommentFragment[];
}

const AdministratorCommentList: React.FC<Props> = ({ comments }) => (
    <>
        {comments.map(comment => (
            <Card key={comment.id} elevation={4} sx={{ width: 600, marginBottom: 2 }}>
                <CardContent>
                    <Box sx={{ display: 'flex', flex: 1 }}>
                        <Typography variant="h5">
                            {actionText(comment.action)}
                        </Typography>
                        <Box sx={{ flex: 1, textAlign: 'right' }}>
                            <FormattedDateTime value={comment.created} />
                            <Typography variant="body2">
                                {comment.administratorName}
                            </Typography>
                        </Box>
                    </Box>
                    <Typography sx={{ whiteSpace: 'pre-wrap', marginTop: '12px' }}>
                        {comment.text}
                    </Typography>
                </CardContent>
            </Card>
        ))}
    </>
);

function actionText(action: AdministratorCommentAction): React.ReactNode {
    switch (action) {
        case AdministratorCommentAction.Activate:
            return <FormattedMessage id="users.administrator-comment.action.activate" />;
        case AdministratorCommentAction.Disable:
            return <FormattedMessage id="users.administrator-comment.action.disable" />;
        case AdministratorCommentAction.Comment:
            return <FormattedMessage id="users.administrator-comment.action.comment" />;
    }
}

export default AdministratorCommentList;
