import React, { useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import { FormattedDate } from 'react-intl';
import type { OpeningException, OpeningHours, OpeningInterval, OpeningPeriod } from '../../../generated/poi-service-internal';
import OpeningIntervalsEditor from './OpeningIntervalsEditor';
import AddExceptionButton from './AddExceptionButton';
import { DAYS } from './constants';
import CopyIntervalsDialog from './CopyIntervalsDialog';
import DayOfWeekText from './DayOfWeekText';

interface Props {
    value: OpeningHours;
    onChange: (value: OpeningHours) => void;
}

const CustomOpeningHoursEditor = ({ value, onChange }: Props) => {
    const [copyingIntervals, setCopyingIntervals] = useState<OpeningInterval[] | null>(null);

    const exceptionTimestamps = useMemo(() => new Set(value.exceptions.map(e => e.date.getTime())), [value.exceptions]);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {DAYS.map((day) => {
                const periodsForDay = value.periods.filter(period => period.day === day);

                const handlePeriodsForDayChange = (intervals: OpeningInterval[]) => {
                    const otherPeriods = value.periods.filter(p => p.day !== day);
                    onChange({
                        ...value,
                        periods: [...otherPeriods, ...intervals.map(({ from, to }) => ({ from, to, day }))],
                    });
                };

                return (
                    <OpeningIntervalsEditor
                        key={day}
                        intervals={periodsForDay}
                        onChange={handlePeriodsForDayChange}
                        onCopy={setCopyingIntervals}
                        label={<DayOfWeekText day={day} />}
                    />
                );
            })}

            {Array.from(exceptionTimestamps).map((timestamp) => {
                const intervalsForDate = value.exceptions.filter(ex => ex.date.getTime() === timestamp);

                const handleExceptionsForDateChange = (intervals: OpeningInterval[]) => {
                    const otherExceptions = value.exceptions.filter(p => p.date.getTime() !== timestamp);

                    onChange({
                        ...value,
                        exceptions: [
                            ...otherExceptions,
                            ...intervals.map(({ from, to }) => ({ from, to, date: new Date(timestamp) })),
                        ].sort(openingExceptionSorter),
                    });
                };

                return (
                    <OpeningIntervalsEditor
                        key={timestamp}
                        canDeleteSingleRow
                        intervals={intervalsForDate}
                        onChange={handleExceptionsForDateChange}
                        label={<FormattedDate value={timestamp} timeZone="UTC" />}
                        onCopy={setCopyingIntervals}
                    />
                );
            })}

            <div>
                <AddExceptionButton
                    onDateSelected={(date) => {
                        onChange({
                            ...value,
                            exceptions: [...value.exceptions, { date, from: 0, to: 0 }].sort(openingExceptionSorter),
                        });
                    }}
                />
            </div>
            <CopyIntervalsDialog
                open={copyingIntervals !== null}
                onClose={() => {
                    setCopyingIntervals(null);
                }}
                onOk={(selectedDays) => {
                    const otherPeriods = value.periods.filter(period => !selectedDays.includes(period.day));

                    const modifiedPeriods = selectedDays.flatMap(day => copyingIntervals!.map(({ from, to }): OpeningPeriod => ({
                        day,
                        from,
                        to,
                    })));

                    onChange({
                        ...value,
                        periods: [...otherPeriods, ...modifiedPeriods],
                    });

                    setCopyingIntervals(null);
                }}
            />
        </Box>
    );
};

function openingExceptionSorter(a: OpeningException, b: OpeningException): number {
    return a.date.getTime() - b.date.getTime();
}

export default CustomOpeningHoursEditor;
