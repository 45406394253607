// TODO copied from backend
const enum Role {
    USERS_READ = 'USERS_READ',
    USERS_WRITE = 'USERS_WRITE',
    NOTIFICATIONS_READ = 'NOTIFICATIONS_READ',
    NOTIFICATIONS_WRITE = 'NOTIFICATIONS_WRITE',
    API_KEYS_READ = 'API_KEYS_READ',
    API_KEYS_WRITE = 'API_KEYS_WRITE',
    MAINTENANCE_READ = 'MAINTENANCE_READ',
    MAINTENANCE_WRITE = 'MAINTENANCE_WRITE',
    POI_READ = 'POI_READ',
    POI_WRITE = 'POI_WRITE',
    TICKETS_READ = 'TICKETS_READ',
    DEBUG = 'DEBUG'
}

export default Role;
