import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useGetAlertDetailsQuery, useUpdateAlertMutation } from '../../graphql/graphql-typings';
import AppAlertForm from '../AlertEditor/AppAlertForm';
import UpdateAlertFormExtraActions from './UpdateAlertFormExtraActions';
import { alertFromFragment, alertInputFromFormValue } from '../AlertEditor/alert-utils';
import type { AppAlertFormValue } from '../AlertEditor/AppAlertFormValue';
import AppAlertProgress from '../AlertEditor/AppAlertProgress';

interface Props {
    alertId: string;
}

const UpdateAlertForm: React.FC<Props> = ({ alertId }) => {
    const { loading: alertLoading, data: alertRequestData } = useGetAlertDetailsQuery({
        variables: { alertId },
        fetchPolicy: 'network-only',
    });

    const [executeUpdateAppAlert, mutationResult] = useUpdateAlertMutation();

    const updateAlert = (formValue: AppAlertFormValue) => {
        const alertInput = alertInputFromFormValue(formValue);

        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        executeUpdateAppAlert({
            variables: {
                alertId,
                alertInput,
            },
            // eslint-disable-next-line @typescript-eslint/no-empty-function
        });
    };

    if (alertLoading || !alertRequestData) {
        return <CircularProgress />;
    }

    const defaultValue = alertFromFragment(alertRequestData.alert);

    return (
        <>
            <AppAlertForm
                defaultValue={defaultValue}
                title={<FormattedMessage id="alert-editor.editing-title" />}
                onSubmit={updateAlert}
                extraActions={<UpdateAlertFormExtraActions alertId={alertId} />}
            />

            <AppAlertProgress
                mutationResult={mutationResult}
                finishedMessage={<FormattedMessage id="alert-editor.alert-modified" />}
            />
        </>
    );
};

export default UpdateAlertForm;
