import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { FormattedMessage } from 'react-intl';
import type { DateTime } from 'luxon';
import { useOpen } from '../../../hooks/useOpen';
import DatePickerDialogContent from './DatePickerDialogContent';

interface Props {
    onDateSelected: (date: Date) => void;
}

const AddExceptionButton: React.FC<Props> = ({ onDateSelected }) => {
    const { open, onOpen, onClose } = useOpen();

    const handleSelect = (date: DateTime) => {
        onClose();
        const result = date.setZone('UTC', { keepLocalTime: true }).startOf('day').toJSDate();
        onDateSelected(result);
    };

    return (
        <>
            <Button onClick={onOpen}>
                <FormattedMessage id="poi-editor.poi.opening-hours.add-exception" />
            </Button>
            <Dialog open={open} onClose={onClose}>
                <DatePickerDialogContent onSelect={handleSelect} />
            </Dialog>
        </>
    );
};

export default AddExceptionButton;
