import React from 'react';
import Stack from '@mui/material/Stack';
import SecondsTimePicker from './SecondsTimePicker';

export interface TimeInterval {
    fromSeconds: number;
    toSeconds: number;
}

interface Props {
    value: TimeInterval;
    onChange: (value: TimeInterval) => void;
}

const TimeIntervalInput = ({ value, onChange }: Props) => {
    const handleFromSecondsChange = (fromSeconds: number) => {
        onChange({ ...value, fromSeconds });
    };

    const handleToSecondsChange = (toSeconds: number) => {
        onChange({ ...value, toSeconds });
    };

    return (
        <Stack spacing={1} direction="row" alignItems="center">
            <SecondsTimePicker seconds={value.fromSeconds} onChange={handleFromSecondsChange} />
            <span>
                -
            </span>
            <SecondsTimePicker seconds={value.toSeconds} onChange={handleToSecondsChange} />
        </Stack>
    );
};

export default TimeIntervalInput;
