import React, { useCallback, useRef, useState } from 'react';
import type { MapLayerMouseEvent } from 'mapbox-gl';
import { LngLat } from 'maplibre-gl';
import TextField from '@mui/material/TextField';
import type { MapRef, ViewState, ViewStateChangeEvent } from 'react-map-gl';
import { Marker } from 'react-map-gl';
import Place from '@mui/icons-material/Place';
import { FormattedMessage } from 'react-intl';
import Stack from '@mui/material/Stack';
import MaplibreMap from '../../components/MaplibreMap';
import { POI_EDITOR_MAP_DEFAULT_VIEW } from '../../config';

interface LonLat {
    longitude: number;
    latitude: number;
}

export type CustomViewState = Pick<ViewState, 'latitude' | 'longitude' | 'zoom'>;

interface Props {
    value: LonLat | undefined;
    onChange: (coordinates: LonLat | undefined) => void;
    onBlur?: () => void;
    error?: boolean;
}

const DEFAULT_VIEW: CustomViewState = POI_EDITOR_MAP_DEFAULT_VIEW ?? { latitude: 0, longitude: 0, zoom: 0 };

const LocationEditor: React.FC<Props> = ({ value, onChange, onBlur, error }) => {
    const valueRef = useRef<LonLat | undefined>(value);
    const [viewState, setViewState] = useState<CustomViewState>((): CustomViewState => {
        if (value) {
            return zoomStateForCoordinate(value);
        }
        return DEFAULT_VIEW;
    });
    const [text, setText] = useState({ value: coordinateToString(value) });
    if (value?.longitude !== valueRef.current?.longitude || value?.latitude !== valueRef.current?.latitude) {
        valueRef.current = value;
        text.value = coordinateToString(value);
    }
    const onMapMove = useCallback((event: ViewStateChangeEvent) => {
        setViewState(event.viewState);
    }, []);

    const handleTextChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const newText = e.target.value;
        const coordinate = parseCoordinate(newText);
        valueRef.current = coordinate;
        setText({ value: newText });
        if (coordinate) {
            setViewState(zoomStateForCoordinate(coordinate));
        }
        onChange(coordinate);
    }, [onChange]);

    const handleClick = useCallback((e: MapLayerMouseEvent) => {
        onChange({
            latitude: e.lngLat.lat,
            longitude: e.lngLat.lng,
        });
        onBlur?.();
    }, [onChange, onBlur]);

    const handleRef = useCallback((mapRef: MapRef | null) => {
        if (!mapRef) {
            return;
        }
        // eslint-disable-next-line no-param-reassign
        mapRef.getMap().getCanvas().style.cursor = 'crosshair';
    }, []);

    return (
        <Stack spacing={2}>
            <MaplibreMap
                ref={handleRef}
                {...viewState}
                onMove={onMapMove}
                onClick={handleClick}
                style={{ height: 360 }}
            >
                {value && (
                    <Marker anchor="bottom" {...value} style={{ display: 'flex', pointerEvents: 'none' }}>
                        <Place fontSize="large" style={{ marginBottom: -4 }} />
                    </Marker>
                )}
            </MaplibreMap>
            <TextField
                error={error}
                label={<FormattedMessage id="poi-editor.poi.coordinates" />}
                value={text.value}
                onChange={handleTextChange}
                onBlur={onBlur}
            />
        </Stack>
    );
};

function zoomStateForCoordinate(coordinate: LonLat): CustomViewState {
    return {
        ...coordinate,
        zoom: 15,
    };
}

function coordinateToString(coordinate: LonLat | undefined): string {
    if (!coordinate) {
        return '';
    }
    return `${coordinate.latitude.toPrecision(10)}, ${coordinate.longitude.toPrecision(10)}`;
}

function parseCoordinate(coordinate: string): LonLat | undefined {
    const parts = coordinate.split(',');
    if (parts.length !== 2) {
        return undefined;
    }
    const [latitude, longitude] = parts.map(part => Number(part.trim()));
    if (!isLngLatValid(longitude, latitude)) {
        return undefined;
    }

    return {
        latitude,
        longitude,
    };
}

function isLngLatValid(longitude: number, latitude: number): boolean {
    try {
        // eslint-disable-next-line no-new
        new LngLat(longitude, latitude);
        return true;
    } catch (e) {
        return false;
    }
}

export default LocationEditor;
