import React from 'react';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import type { ControllerRenderProps } from 'react-hook-form/dist/types/controller';
import { getOpeningHoursType } from '../poi-form-utils';
import OpeningHoursTypeText from './OpeningHoursTypeText';
import CustomOpeningHoursEditor from './CustomOpeningHoursEditor';
import type { PoiFormType } from '../PoiFormValue';
import type { OpeningHours } from '../../../generated/poi-service-internal';
import { DayOfWeek } from '../../../generated/poi-service-internal';
import OpeningHoursType from '../OpeningHoursType';

const OPENING_HOURS_TYPES = [OpeningHoursType.UNSET, OpeningHoursType.O247, OpeningHoursType.CUSTOM];

interface Props {
    field: ControllerRenderProps<PoiFormType, 'openingHours'>;
}

const OpeningHoursEditor: React.FC<Props> = ({ field }) => (
    <>
        <FormControl variant="standard" required>
            <Select
                value={getOpeningHoursType(field.value)}
                onChange={(event) => {
                    const value = event.target.value as OpeningHoursType;
                    field.onChange(getDefaultValueForOpeningHoursType(value));
                }}
                sx={{ minWidth: 180 }}
            >
                {OPENING_HOURS_TYPES.map(openingHoursType => (
                    <MenuItem key={openingHoursType} value={openingHoursType}>
                        <OpeningHoursTypeText type={openingHoursType} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
        {field.value && getOpeningHoursType(field.value) === OpeningHoursType.CUSTOM && (
            <CustomOpeningHoursEditor value={field.value} onChange={field.onChange} />
        )}
    </>
);

function getDefaultValueForOpeningHoursType(type: OpeningHoursType): OpeningHours | undefined {
    switch (type) {
        case OpeningHoursType.UNSET:
            return undefined;
        case OpeningHoursType.O247:
            return {
                periods: [{ day: DayOfWeek.O247, from: 0, to: 0 }],
                exceptions: [],
            };
        case OpeningHoursType.CUSTOM:
            return {
                periods: [],
                exceptions: [],
            };
    }
}

export default OpeningHoursEditor;
