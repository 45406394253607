import React from 'react';
import QueryResultRenderer from '../../components/QueryResultRenderer';
import { useGetUserQuery } from '../../graphql/graphql-typings';
import UserDetailsPanel from './UserDetailsPanel';

interface Props {
    userId: string;
}

const UserDetails: React.FC<Props> = ({ userId }) => {
    const queryResult = useGetUserQuery({
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first',
        variables: { id: userId },
    });

    return (
        <QueryResultRenderer
            queryResult={queryResult}
            component={UserDetailsPanel}
        />
    );
};

export default UserDetails;
