import React, { forwardRef, useMemo, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import type { Theme } from '@mui/material/styles/createTheme';
import type { ReactQuillProps } from 'react-quill';
import ReactQuill from 'react-quill';
import { useForkRef } from '@mui/material';
import { classNames } from '../utils';

const useStyles = makeStyles((theme: Theme) => ({
    description: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        '& .ql-container': {
            height: 'initial',
            flex: 1,
        },
        '& .ql-editor': { minHeight: 300 },
    },
    descriptionError: {
        border: '1px solid',
        borderColor: theme.palette.error.main,
    },
}));

interface Props extends ReactQuillProps {
    error?: boolean;
    disableAlign?: boolean;
    disableImage?: boolean;
}

const HtmlInput = forwardRef<ReactQuill, Props>(({ error, disableAlign = false, disableImage = false, ...rest }, ref) => {
    const classes = useStyles();
    const [currentQuill, setCurrentQuill] = useState<ReactQuill>();
    const forkRef = useForkRef(setCurrentQuill, ref);

    const quillModules = useMemo(() => ({
        toolbar: [
            [{ header: [1, 2, 3, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            !disableAlign && [{ align: ['', 'center', 'right', 'justify'] }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['link', !disableImage && 'image'].filter(Boolean),
            ['clean'],
        ].filter(Boolean),
    }), [disableAlign, disableImage]);

    return (
        <ReactQuill
            theme="snow"
            modules={quillModules}
            bounds={currentQuill?.getEditingArea().parentElement?.parentElement ?? undefined}
            className={classNames(classes.description, error && classes.descriptionError)}
            {...rest}
            ref={forkRef}
        />
    );
});

export default HtmlInput;
