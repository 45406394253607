import CreditCardOutlined from '@mui/icons-material/CreditCardOutlined';
import AccountBoxOutlined from '@mui/icons-material/AccountBoxOutlined';
import FavoriteBorderOutlined from '@mui/icons-material/FavoriteBorderOutlined';
import ChatBubbleOutlineOutlined from '@mui/icons-material/ChatBubbleOutlineOutlined';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import IfRole from '@realcity/web-frame/lib/components/Auth/IfRole';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import BorderedTabs from '../../components/BorderedTabs';
import IconTab from '../../components/IconTab';
import type { GetUserQuery } from '../../graphql/graphql-typings';
import useHasRole from '../../hooks/useHasRole';
import Role from '../../Role';
import UserBaseInfoTable from './UserBaseInfoTable';
import UserChangeEnabledButton from './UserChangeEnabledButton';
import UserFavorites from './UserFavorites';
import UserPurchases from './UserPurchases';
import UserMessages from './UserMessages';
import UserStatusTable from './UserStatusTable';

const enum Tab {
    BASE_INFO = 'BASE_INFO',
    FAVORITES = 'FAVORITES',
    PUSH_NOTIFICATIONS = 'PUSH_NOTIFICATIONS',
    TRANSACTIONS = 'TRANSACTIONS'
}

const useStyles = makeStyles({
    buttonContainer: {
        margin: '24px 0 12px',
    },
});

interface Props {
    user: GetUserQuery['user'] | null;
}

const UserDetailsPanel: React.FC<Props> = ({ user }) => {
    const hasRole = useHasRole(); // cannot use IfRole in Tabs component (onClick and other props are passed to IfRole instead of Tab)
    const [tab, setTab] = useState(Tab.BASE_INFO);

    const classes = useStyles();

    const handleTabChange = (e: unknown, newValue: Tab) => {
        setTab(newValue);
    };

    if (!user) {
        return null;
    }

    return (
        <>
            <Typography variant="h1">{user.displayName}</Typography>
            <BorderedTabs value={tab} onChange={handleTabChange}>
                <IconTab
                    value={Tab.BASE_INFO}
                    icon={<AccountBoxOutlined />}
                    label={<FormattedMessage id="users.base-info" />}
                />
                <IconTab
                    value={Tab.FAVORITES}
                    icon={<FavoriteBorderOutlined />}
                    label={<FormattedMessage id="users.favorites" />}
                />
                {hasRole(Role.DEBUG) && (
                    <IconTab
                        value={Tab.PUSH_NOTIFICATIONS}
                        icon={<ChatBubbleOutlineOutlined />}
                        label={<FormattedMessage id="users.push-notifications" />}
                    />
                )}
                {hasRole(Role.TICKETS_READ) && (
                    <IconTab
                        value={Tab.TRANSACTIONS}
                        icon={<CreditCardOutlined />}
                        label={<FormattedMessage id="users.transactions" />}
                    />
                )}
            </BorderedTabs>
            {tab === Tab.BASE_INFO && (
                <>
                    <UserBaseInfoTable user={user} />
                    <UserStatusTable user={user} />
                    <IfRole role={Role.USERS_WRITE}>
                        <div className={classes.buttonContainer}>
                            <UserChangeEnabledButton user={user} />
                        </div>
                    </IfRole>
                </>
            )}
            {tab === Tab.FAVORITES && <UserFavorites userId={user.id} />}
            {tab === Tab.PUSH_NOTIFICATIONS && <UserMessages userId={user.id} />}
            {tab === Tab.TRANSACTIONS && <UserPurchases userId={user.id} />}
        </>
    );
};

export default UserDetailsPanel;
