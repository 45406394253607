import type { ButtonProps } from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import type { ReactNode } from 'react';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import ConfirmButton from '../../components/ConfirmButton';

interface Props extends Omit<ButtonProps, 'onClick'> {
    confirmMessage: ReactNode;
    onConfirm: (comment: string) => void;
}

const ConfirmWithCommentButton: React.FC<Props> = ({ confirmMessage, onConfirm, ...rest }) => {
    const [comment, setComment] = useState('');

    return (
        <ConfirmButton
            dialogTitle={confirmMessage}
            dialogContent={(
                <TextField
                    multiline
                    minRows={3}
                    fullWidth
                    sx={{ mt: 1 }}
                    label={<FormattedMessage id="users.comments.text" />}
                    value={comment}
                    onChange={(e) => {
                        setComment(e.target.value);
                    }}
                />
            )}
            onConfirm={() => {
                onConfirm(comment);
                setComment('');
            }}
            dialogProps={{ fullWidth: true }}
            {...rest}
        />
    );
};

export default ConfirmWithCommentButton;
