import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: string;
  LocalDateTime: string;
};

export type AdministratorComment = {
  action: AdministratorCommentAction;
  administratorName: Scalars['String'];
  created: Scalars['DateTime'];
  id: Scalars['String'];
  text: Scalars['String'];
};

export enum AdministratorCommentAction {
  Activate = 'ACTIVATE',
  Comment = 'COMMENT',
  Disable = 'DISABLE'
}

export type AlertInput = {
  appAndVersions: InputMaybe<Array<AppVersionAndApiKeyInput>>;
  description: Array<TranslatedStringInput>;
  endTime: InputMaybe<Scalars['DateTime']>;
  forceUpdate: Scalars['Boolean'];
  startTime: Scalars['DateTime'];
  summary: Array<TranslatedStringInput>;
  url: InputMaybe<Array<TranslatedStringInput>>;
};

export type AppAlert = {
  appVersions: Array<AppVersionAndApiKey>;
  description: Array<TranslatedString>;
  endTime: Maybe<Scalars['DateTime']>;
  forceUpdate: Scalars['Boolean'];
  id: Scalars['String'];
  startTime: Scalars['DateTime'];
  summary: Array<TranslatedString>;
  url: Array<TranslatedString>;
};

export type AppVersionAndApiKey = {
  apiKey: Maybe<Scalars['String']>;
  appVersion: Maybe<Scalars['String']>;
};

export type AppVersionAndApiKeyInput = {
  apiKey: InputMaybe<Scalars['String']>;
  appVersion: InputMaybe<Scalars['String']>;
};

export type Country = {
  countryNameId: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isoCode: Maybe<Scalars['String']>;
  zipLength: Maybe<Scalars['Int']>;
};

export enum DayType {
  Everyday = 'EVERYDAY',
  Fri = 'FRI',
  Mon = 'MON',
  Sat = 'SAT',
  Sun = 'SUN',
  Thu = 'THU',
  Tue = 'TUE',
  Wed = 'WED',
  Weekend = 'WEEKEND',
  Workday = 'WORKDAY'
}

export type ExtendedFavoriteItem = {
  favorite: Maybe<UnifiedFavoriteItem>;
  id: Scalars['String'];
  subscription: Maybe<Subscription>;
};

export enum FavoriteType {
  OfflineMap = 'OFFLINE_MAP',
  PlaceHome = 'PLACE_HOME',
  PlaceOther = 'PLACE_OTHER',
  PlaceWork = 'PLACE_WORK',
  Route = 'ROUTE',
  Stop = 'STOP'
}

export type IdentityProvider = {
  name: Scalars['String'];
  userName: Scalars['String'];
};

export type ManagedUser = {
  administratorComments: Array<AdministratorComment>;
  customerId: Scalars['String'];
  deletedAt: Maybe<Scalars['DateTime']>;
  deletionReason: Maybe<Scalars['String']>;
  displayName: Scalars['String'];
  email: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  hasPassword: Scalars['Boolean'];
  id: Scalars['String'];
  identityProviders: Array<IdentityProvider>;
  locale: Maybe<Scalars['String']>;
  marketingConsent: Scalars['Boolean'];
  phoneNumber: Maybe<Scalars['String']>;
  registeredAt: Scalars['DateTime'];
  status: ManagedUserStatus;
};

export enum ManagedUserStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
  Disabled = 'DISABLED'
}

export type Message = {
  allDevices: Scalars['Boolean'];
  id: Scalars['String'];
  sent: Scalars['DateTime'];
  text: Array<TranslatedString>;
  title: Array<TranslatedString>;
  users: Array<ManagedUser>;
};

export type MessageInput = {
  addressee: InputMaybe<Scalars['String']>;
  image: InputMaybe<Scalars['String']>;
  text: Array<TranslatedStringInput>;
  title: Array<TranslatedStringInput>;
  url: InputMaybe<Scalars['String']>;
};

export type Mutation = {
  addComment: ManagedUser;
  createAlert: Scalars['String'];
  deleteAlert: AppAlert;
  sendMessage: Scalars['String'];
  setState: UserApiKey;
  setUserEnabled: ManagedUser;
  updateAlert: AppAlert;
};


export type MutationAddCommentArgs = {
  comment: Scalars['String'];
  id: Scalars['String'];
};


export type MutationCreateAlertArgs = {
  alert: AlertInput;
};


export type MutationDeleteAlertArgs = {
  alertId: Scalars['String'];
};


export type MutationSendMessageArgs = {
  message: MessageInput;
};


export type MutationSetStateArgs = {
  key: Scalars['String'];
  state: UserApiKeyState;
};


export type MutationSetUserEnabledArgs = {
  comment: Scalars['String'];
  enabled: Scalars['Boolean'];
  id: Scalars['String'];
};


export type MutationUpdateAlertArgs = {
  alert: AlertInput;
  alertId: Scalars['String'];
};

export type PaginatedApiKeyResponse = {
  items: Array<UserApiKey>;
  paginationInfo: PaginationInfo;
};

export type PaginatedAppAlertResponse = {
  items: Array<AppAlert>;
  paginationInfo: PaginationInfo;
};

export type PaginatedMessageResponse = {
  items: Array<Message>;
  paginationInfo: PaginationInfo;
};

export type PaginatedUserMessageResponse = {
  items: Array<UserMessage>;
  paginationInfo: PaginationInfo;
};

export type PaginatedUserResponse = {
  items: Array<ManagedUser>;
  paginationInfo: PaginationInfo;
};

export type Pagination = {
  cursor: InputMaybe<Scalars['String']>;
  rows: Scalars['Int'];
};

export type PaginationInfo = {
  nextCursor: Maybe<Scalars['String']>;
  previousCursor: Maybe<Scalars['String']>;
  totalCount: Maybe<Scalars['Int']>;
};

export type Purchase = {
  autoPurchaseEnabled: Maybe<Scalars['Boolean']>;
  autoPurchaseEnabledTime: Maybe<Scalars['String']>;
  card: Maybe<PurchaseCard>;
  child: Maybe<Purchase>;
  costInfo: Maybe<Scalars['Float']>;
  id: Scalars['Int'];
  invoiceData: Maybe<PurchaseInvoice>;
  orderRef: Maybe<Scalars['String']>;
  parent: Maybe<Purchase>;
  productNMFRId: Maybe<Scalars['String']>;
  productNameHU: Scalars['String'];
  productOwnerName: Scalars['String'];
  productUniquePrefix: Maybe<Scalars['String']>;
  purchaseDate: Maybe<Scalars['LocalDateTime']>;
  purchaseHeadId: Maybe<Scalars['String']>;
  purchaseNMFRId: Maybe<Scalars['String']>;
  purchasePaymentId: Maybe<Scalars['String']>;
  status: Maybe<UserPurchaseStatus>;
  storno: Maybe<Scalars['Boolean']>;
  stornoDate: Maybe<Scalars['LocalDateTime']>;
  transactionId: Maybe<Scalars['String']>;
  usabilityEnd: Maybe<Scalars['LocalDateTime']>;
  validityEnd: Maybe<Scalars['LocalDateTime']>;
  validityStart: Maybe<Scalars['LocalDateTime']>;
};

export type PurchaseCard = {
  active: Maybe<Scalars['Boolean']>;
  cardNumber: Maybe<Scalars['String']>;
  created: Maybe<Scalars['LocalDateTime']>;
  id: Maybe<Scalars['String']>;
  modified: Maybe<Scalars['LocalDateTime']>;
  validity: Maybe<Scalars['LocalDateTime']>;
};

export type PurchaseInvoice = {
  address: Maybe<Scalars['String']>;
  addressName: Maybe<Scalars['String']>;
  cityName: Maybe<Scalars['String']>;
  country: Maybe<Country>;
  email: Maybe<Scalars['String']>;
  groupTaxNumber: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  invoiceNumber: Maybe<Scalars['String']>;
  postalCode: Maybe<Scalars['String']>;
  taxNumber: Maybe<Scalars['String']>;
};

export type Query = {
  alert: AppAlert;
  alerts: PaginatedAppAlertResponse;
  extendedFavorites: Array<ExtendedFavoriteItem>;
  favorites: Array<UnifiedFavoriteItem>;
  keys: PaginatedApiKeyResponse;
  listUsers: PaginatedUserResponse;
  messages: PaginatedMessageResponse;
  purchases: Array<Purchase>;
  user: Maybe<ManagedUser>;
  userMessages: PaginatedUserMessageResponse;
  validations: Array<Validation>;
};


export type QueryAlertArgs = {
  alertId: Scalars['String'];
};


export type QueryAlertsArgs = {
  pagination: Pagination;
};


export type QueryExtendedFavoritesArgs = {
  userId: Scalars['String'];
};


export type QueryFavoritesArgs = {
  userId: Scalars['String'];
};


export type QueryKeysArgs = {
  pagination: Pagination;
  search: InputMaybe<Scalars['String']>;
};


export type QueryListUsersArgs = {
  pagination: Pagination;
  search: InputMaybe<Scalars['String']>;
};


export type QueryMessagesArgs = {
  pagination: Pagination;
};


export type QueryPurchasesArgs = {
  userId: Scalars['String'];
};


export type QueryUserArgs = {
  id: Scalars['String'];
};


export type QueryUserMessagesArgs = {
  pagination: Pagination;
  userId: Scalars['String'];
};


export type QueryValidationsArgs = {
  purchaseId: Scalars['Int'];
};

export type Subscription = {
  excludedEffects: Array<TransitAlertEffect>;
  id: Scalars['String'];
  routeId: Maybe<Scalars['String']>;
  stopIds: Array<Scalars['String']>;
  time: Array<SubscriptionTime>;
};

export type SubscriptionTime = {
  day: DayType;
  from: Scalars['Int'];
  to: Scalars['Int'];
};

export enum TransitAlertEffect {
  SignificantDelays = 'SIGNIFICANT_DELAYS'
}

export type TranslatedString = {
  locale: Scalars['String'];
  text: Scalars['String'];
};

export type TranslatedStringInput = {
  locale: Scalars['String'];
  text: Scalars['String'];
};

export type UnifiedFavoriteItem = {
  entityId: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  type: FavoriteType;
};

export type UserApiKey = {
  comment: Scalars['String'];
  companyName: Maybe<Scalars['String']>;
  createdTime: Scalars['DateTime'];
  id: Scalars['String'];
  state: UserApiKeyState;
  user: ManagedUser;
  userId: Scalars['String'];
};

export enum UserApiKeyState {
  Activated = 'ACTIVATED',
  Blocked = 'BLOCKED',
  Deleted = 'DELETED',
  Pending = 'PENDING'
}

export type UserMessage = {
  id: Scalars['String'];
  message: Message;
  status: UserMessageStatus;
};

export enum UserMessageStatus {
  Failure = 'FAILURE',
  NoToken = 'NO_TOKEN',
  Pending = 'PENDING',
  Success = 'SUCCESS'
}

export enum UserPurchaseStatus {
  Ideiglenes = 'IDEIGLENES',
  Kifizetett = 'KIFIZETETT',
  NmfrSztorno = 'NMFR_SZTORNO',
  NmfrSztornoraVar = 'NMFR_SZTORNORA_VAR',
  Torolt = 'TOROLT',
  Visszavaltas = 'VISSZAVALTAS',
  Visszavalto = 'VISSZAVALTO'
}

export type Validation = {
  id: Scalars['String'];
  validationDate: Scalars['LocalDateTime'];
  validationResult: ValidityValidationResultEnum;
  validationType: Maybe<ValidityValidationTypeEnum>;
};

export enum ValidityValidationResultEnum {
  NoResponse = 'NO_RESPONSE',
  Successful = 'SUCCESSFUL',
  Unsuccessful = 'UNSUCCESSFUL'
}

export enum ValidityValidationTypeEnum {
  Metro = 'METRO',
  Nfc = 'NFC',
  Qr = 'QR'
}

export type AppVersionAndApiKeyFragment = { apiKey: string | null, appVersion: string | null };

export type AppAlertSummaryFragment = { id: string, startTime: string, endTime: string | null, summary: Array<{ locale: string, text: string }>, appVersions: Array<{ apiKey: string | null, appVersion: string | null }> };

export type AppAlertDetailsFragment = { startTime: string, endTime: string | null, forceUpdate: boolean, summary: Array<{ locale: string, text: string }>, description: Array<{ locale: string, text: string }>, url: Array<{ locale: string, text: string }>, appVersions: Array<{ apiKey: string | null, appVersion: string | null }> };

export type ListAlertsQueryVariables = Exact<{
  pagination: Pagination;
}>;


export type ListAlertsQuery = { alerts: { paginationInfo: { totalCount: number | null, previousCursor: string | null, nextCursor: string | null }, items: Array<{ id: string, startTime: string, endTime: string | null, summary: Array<{ locale: string, text: string }>, appVersions: Array<{ apiKey: string | null, appVersion: string | null }> }> } };

export type GetAlertDetailsQueryVariables = Exact<{
  alertId: Scalars['String'];
}>;


export type GetAlertDetailsQuery = { alert: { startTime: string, endTime: string | null, forceUpdate: boolean, summary: Array<{ locale: string, text: string }>, description: Array<{ locale: string, text: string }>, url: Array<{ locale: string, text: string }>, appVersions: Array<{ apiKey: string | null, appVersion: string | null }> } };

export type CreateAppAlertMutationVariables = Exact<{
  alertInput: AlertInput;
}>;


export type CreateAppAlertMutation = { createAlert: string };

export type UpdateAlertMutationVariables = Exact<{
  alertId: Scalars['String'];
  alertInput: AlertInput;
}>;


export type UpdateAlertMutation = { updateAlert: { id: string } };

export type DeleteAlertMutationVariables = Exact<{
  alertId: Scalars['String'];
}>;


export type DeleteAlertMutation = { deleteAlert: { id: string } };

export type FullPaginationInfoFragment = { totalCount: number | null, previousCursor: string | null, nextCursor: string | null };

export type TranslatedStringFragment = { locale: string, text: string };

export type SubscriptionTimeFragment = { day: DayType, from: number, to: number };

export type ExtendedUserFavoriteItemFragment = { id: string, favorite: { type: FavoriteType, name: string, entityId: string } | null, subscription: { excludedEffects: Array<TransitAlertEffect>, stopIds: Array<string>, routeId: string | null, time: Array<{ day: DayType, from: number, to: number }> } | null };

export type ListExtendedFavoritesQueryVariables = Exact<{
  userId: Scalars['String'];
}>;


export type ListExtendedFavoritesQuery = { extendedFavorites: Array<{ id: string, favorite: { type: FavoriteType, name: string, entityId: string } | null, subscription: { excludedEffects: Array<TransitAlertEffect>, stopIds: Array<string>, routeId: string | null, time: Array<{ day: DayType, from: number, to: number }> } | null }> };

export type UserFavoriteNameFragment = { name: string, entityId: string };

export type UserFavoritesFragment = { id: string, type: FavoriteType, name: string, entityId: string };

export type ListFavoritesQueryVariables = Exact<{
  userId: Scalars['String'];
}>;


export type ListFavoritesQuery = { favorites: Array<{ id: string, type: FavoriteType, name: string, entityId: string }> };

export type ListKeysQueryVariables = Exact<{
  pagination: Pagination;
  search: InputMaybe<Scalars['String']>;
}>;


export type ListKeysQuery = { keys: { paginationInfo: { totalCount: number | null, previousCursor: string | null, nextCursor: string | null }, items: Array<{ companyName: string | null, comment: string, createdTime: string, state: UserApiKeyState, id: string, user: { id: string, displayName: string } }> } };

export type SetStateMutationVariables = Exact<{
  key: Scalars['String'];
  state: UserApiKeyState;
}>;


export type SetStateMutation = { setState: { id: string, state: UserApiKeyState } };

export type ListMessagesQueryVariables = Exact<{
  pagination: Pagination;
}>;


export type ListMessagesQuery = { messages: { paginationInfo: { totalCount: number | null, previousCursor: string | null, nextCursor: string | null }, items: Array<{ id: string, allDevices: boolean, sent: string, users: Array<{ id: string, displayName: string }>, title: Array<{ locale: string, text: string }>, text: Array<{ locale: string, text: string }> }> } };

export type SendMessageMutationVariables = Exact<{
  message: MessageInput;
}>;


export type SendMessageMutation = { sendMessage: string };

export type UserPurchaseInvoiceDetailsFragment = { invoiceNumber: string | null, addressName: string | null, email: string | null, postalCode: string | null, cityName: string | null, address: string | null, taxNumber: string | null, groupTaxNumber: string | null, country: { isoCode: string | null } | null };

export type UserPurchaseCardDetailsFragment = { id: string | null, validity: string | null, cardNumber: string | null, active: boolean | null, created: string | null, modified: string | null };

export type UserPurchaseDetailsFragment = { id: number, purchaseHeadId: string | null, transactionId: string | null, orderRef: string | null, purchaseNMFRId: string | null, productUniquePrefix: string | null, productNameHU: string, productOwnerName: string, validityStart: string | null, validityEnd: string | null, purchaseDate: string | null, autoPurchaseEnabled: boolean | null, autoPurchaseEnabledTime: string | null, costInfo: number | null, storno: boolean | null, stornoDate: string | null, status: UserPurchaseStatus | null, usabilityEnd: string | null, parent: { invoiceData: { invoiceNumber: string | null } | null } | null, child: { invoiceData: { invoiceNumber: string | null } | null } | null, invoiceData: { invoiceNumber: string | null, addressName: string | null, email: string | null, postalCode: string | null, cityName: string | null, address: string | null, taxNumber: string | null, groupTaxNumber: string | null, country: { isoCode: string | null } | null } | null, card: { id: string | null, validity: string | null, cardNumber: string | null, active: boolean | null, created: string | null, modified: string | null } | null };

export type ListPurchasesQueryVariables = Exact<{
  userId: Scalars['String'];
}>;


export type ListPurchasesQuery = { purchases: Array<{ id: number, purchaseHeadId: string | null, transactionId: string | null, orderRef: string | null, purchaseNMFRId: string | null, productUniquePrefix: string | null, productNameHU: string, productOwnerName: string, validityStart: string | null, validityEnd: string | null, purchaseDate: string | null, autoPurchaseEnabled: boolean | null, autoPurchaseEnabledTime: string | null, costInfo: number | null, storno: boolean | null, stornoDate: string | null, status: UserPurchaseStatus | null, usabilityEnd: string | null, parent: { invoiceData: { invoiceNumber: string | null } | null } | null, child: { invoiceData: { invoiceNumber: string | null } | null } | null, invoiceData: { invoiceNumber: string | null, addressName: string | null, email: string | null, postalCode: string | null, cityName: string | null, address: string | null, taxNumber: string | null, groupTaxNumber: string | null, country: { isoCode: string | null } | null } | null, card: { id: string | null, validity: string | null, cardNumber: string | null, active: boolean | null, created: string | null, modified: string | null } | null }> };

export type ListUserMessagesQueryVariables = Exact<{
  userId: Scalars['String'];
  pagination: Pagination;
}>;


export type ListUserMessagesQuery = { userMessages: { paginationInfo: { totalCount: number | null, previousCursor: string | null, nextCursor: string | null }, items: Array<{ id: string, status: UserMessageStatus, message: { sent: string, text: Array<{ locale: string, text: string }>, title: Array<{ locale: string, text: string }> } }> } };

export type UserAutocompleteOptionFragment = { id: string, customerId: string, displayName: string, email: string | null };

export type SearchUsersQueryVariables = Exact<{
  search: Scalars['String'];
  rows: Scalars['Int'];
}>;


export type SearchUsersQuery = { listUsers: { items: Array<{ id: string, customerId: string, displayName: string, email: string | null }> } };

export type UserLinkFragment = { id: string, displayName: string };

export type ListUsersQueryVariables = Exact<{
  pagination: Pagination;
  search: InputMaybe<Scalars['String']>;
}>;


export type ListUsersQuery = { listUsers: { paginationInfo: { totalCount: number | null, previousCursor: string | null, nextCursor: string | null }, items: Array<{ customerId: string, email: string | null, status: ManagedUserStatus, registeredAt: string, id: string, displayName: string }> } };

export type UserEnabledFragment = { id: string, enabled: boolean };

export type AdministratorCommentFragment = { id: string, administratorName: string, text: string, created: string, action: AdministratorCommentAction };

export type UserBaseInfoFragment = { id: string, customerId: string, displayName: string, email: string | null, locale: string | null, phoneNumber: string | null, marketingConsent: boolean, hasPassword: boolean, identityProviders: Array<{ name: string, userName: string }>, administratorComments: Array<{ id: string, administratorName: string, text: string, created: string, action: AdministratorCommentAction }> };

export type UserStatusInfoFragment = { status: ManagedUserStatus, registeredAt: string, deletedAt: string | null, deletionReason: string | null };

export type GetUserQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetUserQuery = { user: { id: string, enabled: boolean, customerId: string, displayName: string, email: string | null, locale: string | null, phoneNumber: string | null, marketingConsent: boolean, hasPassword: boolean, status: ManagedUserStatus, registeredAt: string, deletedAt: string | null, deletionReason: string | null, identityProviders: Array<{ name: string, userName: string }>, administratorComments: Array<{ id: string, administratorName: string, text: string, created: string, action: AdministratorCommentAction }> } | null };

export type SetUserEnabledMutationVariables = Exact<{
  id: Scalars['String'];
  enabled: Scalars['Boolean'];
  comment: Scalars['String'];
}>;


export type SetUserEnabledMutation = { setUserEnabled: { status: ManagedUserStatus, id: string, enabled: boolean, administratorComments: Array<{ id: string, administratorName: string, text: string, created: string, action: AdministratorCommentAction }> } };

export type AddCommentMutationVariables = Exact<{
  id: Scalars['String'];
  comment: Scalars['String'];
}>;


export type AddCommentMutation = { addComment: { id: string, administratorComments: Array<{ id: string, administratorName: string, text: string, created: string, action: AdministratorCommentAction }> } };

export type ValidationInfoFragment = { id: string, validationDate: string, validationType: ValidityValidationTypeEnum | null, validationResult: ValidityValidationResultEnum };

export type ListValidationsQueryVariables = Exact<{
  purchaseId: Scalars['Int'];
}>;


export type ListValidationsQuery = { validations: Array<{ id: string, validationDate: string, validationType: ValidityValidationTypeEnum | null, validationResult: ValidityValidationResultEnum }> };

export const TranslatedStringFragmentDoc = gql`
    fragment TranslatedString on TranslatedString {
  locale
  text
}
    `;
export const AppVersionAndApiKeyFragmentDoc = gql`
    fragment appVersionAndApiKey on AppVersionAndApiKey {
  apiKey
  appVersion
}
    `;
export const AppAlertSummaryFragmentDoc = gql`
    fragment appAlertSummary on AppAlert {
  id
  startTime
  endTime
  summary {
    ...TranslatedString
  }
  appVersions {
    ...appVersionAndApiKey
  }
}
    ${TranslatedStringFragmentDoc}
${AppVersionAndApiKeyFragmentDoc}`;
export const AppAlertDetailsFragmentDoc = gql`
    fragment appAlertDetails on AppAlert {
  startTime
  endTime
  summary {
    ...TranslatedString
  }
  description {
    ...TranslatedString
  }
  url {
    ...TranslatedString
  }
  appVersions {
    ...appVersionAndApiKey
  }
  forceUpdate
}
    ${TranslatedStringFragmentDoc}
${AppVersionAndApiKeyFragmentDoc}`;
export const FullPaginationInfoFragmentDoc = gql`
    fragment fullPaginationInfo on PaginationInfo {
  totalCount
  previousCursor
  nextCursor
}
    `;
export const UserFavoriteNameFragmentDoc = gql`
    fragment userFavoriteName on UnifiedFavoriteItem {
  name
  entityId
}
    `;
export const SubscriptionTimeFragmentDoc = gql`
    fragment subscriptionTime on SubscriptionTime {
  day
  from
  to
}
    `;
export const ExtendedUserFavoriteItemFragmentDoc = gql`
    fragment extendedUserFavoriteItem on ExtendedFavoriteItem {
  id
  favorite {
    type
    ...userFavoriteName
  }
  subscription {
    time {
      ...subscriptionTime
    }
    excludedEffects
    stopIds
    routeId
  }
}
    ${UserFavoriteNameFragmentDoc}
${SubscriptionTimeFragmentDoc}`;
export const UserFavoritesFragmentDoc = gql`
    fragment userFavorites on UnifiedFavoriteItem {
  id
  type
  ...userFavoriteName
}
    ${UserFavoriteNameFragmentDoc}`;
export const UserPurchaseInvoiceDetailsFragmentDoc = gql`
    fragment userPurchaseInvoiceDetails on PurchaseInvoice {
  invoiceNumber
  addressName
  email
  country {
    isoCode
  }
  postalCode
  cityName
  address
  taxNumber
  groupTaxNumber
}
    `;
export const UserPurchaseCardDetailsFragmentDoc = gql`
    fragment userPurchaseCardDetails on PurchaseCard {
  id
  validity
  cardNumber
  active
  created
  modified
}
    `;
export const UserPurchaseDetailsFragmentDoc = gql`
    fragment userPurchaseDetails on Purchase {
  id
  purchaseHeadId
  transactionId
  orderRef
  purchaseNMFRId
  productUniquePrefix
  productNameHU
  productOwnerName
  validityStart
  validityEnd
  purchaseDate
  autoPurchaseEnabled
  autoPurchaseEnabledTime
  costInfo
  storno
  stornoDate
  parent {
    invoiceData {
      invoiceNumber
    }
  }
  child {
    invoiceData {
      invoiceNumber
    }
  }
  status
  usabilityEnd
  invoiceData {
    ...userPurchaseInvoiceDetails
  }
  card {
    ...userPurchaseCardDetails
  }
}
    ${UserPurchaseInvoiceDetailsFragmentDoc}
${UserPurchaseCardDetailsFragmentDoc}`;
export const UserAutocompleteOptionFragmentDoc = gql`
    fragment userAutocompleteOption on ManagedUser {
  id
  customerId
  displayName
  email
}
    `;
export const UserLinkFragmentDoc = gql`
    fragment userLink on ManagedUser {
  id
  displayName
}
    `;
export const UserEnabledFragmentDoc = gql`
    fragment userEnabled on ManagedUser {
  id
  enabled
}
    `;
export const AdministratorCommentFragmentDoc = gql`
    fragment administratorComment on AdministratorComment {
  id
  administratorName
  text
  created
  action
}
    `;
export const UserBaseInfoFragmentDoc = gql`
    fragment userBaseInfo on ManagedUser {
  id
  customerId
  displayName
  email
  locale
  phoneNumber
  marketingConsent
  identityProviders {
    name
    userName
  }
  hasPassword
  administratorComments {
    ...administratorComment
  }
}
    ${AdministratorCommentFragmentDoc}`;
export const UserStatusInfoFragmentDoc = gql`
    fragment userStatusInfo on ManagedUser {
  status
  registeredAt
  deletedAt
  deletionReason
}
    `;
export const ValidationInfoFragmentDoc = gql`
    fragment validationInfo on Validation {
  id
  validationDate
  validationType
  validationResult
}
    `;
export const ListAlertsDocument = gql`
    query listAlerts($pagination: Pagination!) {
  alerts(pagination: $pagination) {
    paginationInfo {
      ...fullPaginationInfo
    }
    items {
      ...appAlertSummary
    }
  }
}
    ${FullPaginationInfoFragmentDoc}
${AppAlertSummaryFragmentDoc}`;

/**
 * __useListAlertsQuery__
 *
 * To run a query within a React component, call `useListAlertsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAlertsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAlertsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useListAlertsQuery(baseOptions: Apollo.QueryHookOptions<ListAlertsQuery, ListAlertsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAlertsQuery, ListAlertsQueryVariables>(ListAlertsDocument, options);
      }
export function useListAlertsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAlertsQuery, ListAlertsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAlertsQuery, ListAlertsQueryVariables>(ListAlertsDocument, options);
        }
export type ListAlertsQueryHookResult = ReturnType<typeof useListAlertsQuery>;
export type ListAlertsLazyQueryHookResult = ReturnType<typeof useListAlertsLazyQuery>;
export type ListAlertsQueryResult = Apollo.QueryResult<ListAlertsQuery, ListAlertsQueryVariables>;
export const GetAlertDetailsDocument = gql`
    query getAlertDetails($alertId: String!) {
  alert(alertId: $alertId) {
    ...appAlertDetails
  }
}
    ${AppAlertDetailsFragmentDoc}`;

/**
 * __useGetAlertDetailsQuery__
 *
 * To run a query within a React component, call `useGetAlertDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAlertDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAlertDetailsQuery({
 *   variables: {
 *      alertId: // value for 'alertId'
 *   },
 * });
 */
export function useGetAlertDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetAlertDetailsQuery, GetAlertDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAlertDetailsQuery, GetAlertDetailsQueryVariables>(GetAlertDetailsDocument, options);
      }
export function useGetAlertDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAlertDetailsQuery, GetAlertDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAlertDetailsQuery, GetAlertDetailsQueryVariables>(GetAlertDetailsDocument, options);
        }
export type GetAlertDetailsQueryHookResult = ReturnType<typeof useGetAlertDetailsQuery>;
export type GetAlertDetailsLazyQueryHookResult = ReturnType<typeof useGetAlertDetailsLazyQuery>;
export type GetAlertDetailsQueryResult = Apollo.QueryResult<GetAlertDetailsQuery, GetAlertDetailsQueryVariables>;
export const CreateAppAlertDocument = gql`
    mutation createAppAlert($alertInput: AlertInput!) {
  createAlert(alert: $alertInput)
}
    `;
export type CreateAppAlertMutationFn = Apollo.MutationFunction<CreateAppAlertMutation, CreateAppAlertMutationVariables>;

/**
 * __useCreateAppAlertMutation__
 *
 * To run a mutation, you first call `useCreateAppAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAppAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAppAlertMutation, { data, loading, error }] = useCreateAppAlertMutation({
 *   variables: {
 *      alertInput: // value for 'alertInput'
 *   },
 * });
 */
export function useCreateAppAlertMutation(baseOptions?: Apollo.MutationHookOptions<CreateAppAlertMutation, CreateAppAlertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAppAlertMutation, CreateAppAlertMutationVariables>(CreateAppAlertDocument, options);
      }
export type CreateAppAlertMutationHookResult = ReturnType<typeof useCreateAppAlertMutation>;
export type CreateAppAlertMutationResult = Apollo.MutationResult<CreateAppAlertMutation>;
export type CreateAppAlertMutationOptions = Apollo.BaseMutationOptions<CreateAppAlertMutation, CreateAppAlertMutationVariables>;
export const UpdateAlertDocument = gql`
    mutation updateAlert($alertId: String!, $alertInput: AlertInput!) {
  updateAlert(alertId: $alertId, alert: $alertInput) {
    id
  }
}
    `;
export type UpdateAlertMutationFn = Apollo.MutationFunction<UpdateAlertMutation, UpdateAlertMutationVariables>;

/**
 * __useUpdateAlertMutation__
 *
 * To run a mutation, you first call `useUpdateAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAlertMutation, { data, loading, error }] = useUpdateAlertMutation({
 *   variables: {
 *      alertId: // value for 'alertId'
 *      alertInput: // value for 'alertInput'
 *   },
 * });
 */
export function useUpdateAlertMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAlertMutation, UpdateAlertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAlertMutation, UpdateAlertMutationVariables>(UpdateAlertDocument, options);
      }
export type UpdateAlertMutationHookResult = ReturnType<typeof useUpdateAlertMutation>;
export type UpdateAlertMutationResult = Apollo.MutationResult<UpdateAlertMutation>;
export type UpdateAlertMutationOptions = Apollo.BaseMutationOptions<UpdateAlertMutation, UpdateAlertMutationVariables>;
export const DeleteAlertDocument = gql`
    mutation deleteAlert($alertId: String!) {
  deleteAlert(alertId: $alertId) {
    id
  }
}
    `;
export type DeleteAlertMutationFn = Apollo.MutationFunction<DeleteAlertMutation, DeleteAlertMutationVariables>;

/**
 * __useDeleteAlertMutation__
 *
 * To run a mutation, you first call `useDeleteAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAlertMutation, { data, loading, error }] = useDeleteAlertMutation({
 *   variables: {
 *      alertId: // value for 'alertId'
 *   },
 * });
 */
export function useDeleteAlertMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAlertMutation, DeleteAlertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAlertMutation, DeleteAlertMutationVariables>(DeleteAlertDocument, options);
      }
export type DeleteAlertMutationHookResult = ReturnType<typeof useDeleteAlertMutation>;
export type DeleteAlertMutationResult = Apollo.MutationResult<DeleteAlertMutation>;
export type DeleteAlertMutationOptions = Apollo.BaseMutationOptions<DeleteAlertMutation, DeleteAlertMutationVariables>;
export const ListExtendedFavoritesDocument = gql`
    query listExtendedFavorites($userId: String!) {
  extendedFavorites(userId: $userId) {
    ...extendedUserFavoriteItem
  }
}
    ${ExtendedUserFavoriteItemFragmentDoc}`;

/**
 * __useListExtendedFavoritesQuery__
 *
 * To run a query within a React component, call `useListExtendedFavoritesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListExtendedFavoritesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListExtendedFavoritesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useListExtendedFavoritesQuery(baseOptions: Apollo.QueryHookOptions<ListExtendedFavoritesQuery, ListExtendedFavoritesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListExtendedFavoritesQuery, ListExtendedFavoritesQueryVariables>(ListExtendedFavoritesDocument, options);
      }
export function useListExtendedFavoritesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListExtendedFavoritesQuery, ListExtendedFavoritesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListExtendedFavoritesQuery, ListExtendedFavoritesQueryVariables>(ListExtendedFavoritesDocument, options);
        }
export type ListExtendedFavoritesQueryHookResult = ReturnType<typeof useListExtendedFavoritesQuery>;
export type ListExtendedFavoritesLazyQueryHookResult = ReturnType<typeof useListExtendedFavoritesLazyQuery>;
export type ListExtendedFavoritesQueryResult = Apollo.QueryResult<ListExtendedFavoritesQuery, ListExtendedFavoritesQueryVariables>;
export const ListFavoritesDocument = gql`
    query listFavorites($userId: String!) {
  favorites(userId: $userId) {
    ...userFavorites
  }
}
    ${UserFavoritesFragmentDoc}`;

/**
 * __useListFavoritesQuery__
 *
 * To run a query within a React component, call `useListFavoritesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListFavoritesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListFavoritesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useListFavoritesQuery(baseOptions: Apollo.QueryHookOptions<ListFavoritesQuery, ListFavoritesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListFavoritesQuery, ListFavoritesQueryVariables>(ListFavoritesDocument, options);
      }
export function useListFavoritesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListFavoritesQuery, ListFavoritesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListFavoritesQuery, ListFavoritesQueryVariables>(ListFavoritesDocument, options);
        }
export type ListFavoritesQueryHookResult = ReturnType<typeof useListFavoritesQuery>;
export type ListFavoritesLazyQueryHookResult = ReturnType<typeof useListFavoritesLazyQuery>;
export type ListFavoritesQueryResult = Apollo.QueryResult<ListFavoritesQuery, ListFavoritesQueryVariables>;
export const ListKeysDocument = gql`
    query listKeys($pagination: Pagination!, $search: String) {
  keys(pagination: $pagination, search: $search) {
    paginationInfo {
      ...fullPaginationInfo
    }
    items {
      user {
        ...userLink
      }
      companyName
      comment
      createdTime
      state
      id
    }
  }
}
    ${FullPaginationInfoFragmentDoc}
${UserLinkFragmentDoc}`;

/**
 * __useListKeysQuery__
 *
 * To run a query within a React component, call `useListKeysQuery` and pass it any options that fit your needs.
 * When your component renders, `useListKeysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListKeysQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useListKeysQuery(baseOptions: Apollo.QueryHookOptions<ListKeysQuery, ListKeysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListKeysQuery, ListKeysQueryVariables>(ListKeysDocument, options);
      }
export function useListKeysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListKeysQuery, ListKeysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListKeysQuery, ListKeysQueryVariables>(ListKeysDocument, options);
        }
export type ListKeysQueryHookResult = ReturnType<typeof useListKeysQuery>;
export type ListKeysLazyQueryHookResult = ReturnType<typeof useListKeysLazyQuery>;
export type ListKeysQueryResult = Apollo.QueryResult<ListKeysQuery, ListKeysQueryVariables>;
export const SetStateDocument = gql`
    mutation setState($key: String!, $state: UserApiKeyState!) {
  setState(key: $key, state: $state) {
    id
    state
  }
}
    `;
export type SetStateMutationFn = Apollo.MutationFunction<SetStateMutation, SetStateMutationVariables>;

/**
 * __useSetStateMutation__
 *
 * To run a mutation, you first call `useSetStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setStateMutation, { data, loading, error }] = useSetStateMutation({
 *   variables: {
 *      key: // value for 'key'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useSetStateMutation(baseOptions?: Apollo.MutationHookOptions<SetStateMutation, SetStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetStateMutation, SetStateMutationVariables>(SetStateDocument, options);
      }
export type SetStateMutationHookResult = ReturnType<typeof useSetStateMutation>;
export type SetStateMutationResult = Apollo.MutationResult<SetStateMutation>;
export type SetStateMutationOptions = Apollo.BaseMutationOptions<SetStateMutation, SetStateMutationVariables>;
export const ListMessagesDocument = gql`
    query listMessages($pagination: Pagination!) {
  messages(pagination: $pagination) {
    paginationInfo {
      ...fullPaginationInfo
    }
    items {
      id
      allDevices
      users {
        ...userLink
      }
      sent
      title {
        ...TranslatedString
      }
      text {
        ...TranslatedString
      }
    }
  }
}
    ${FullPaginationInfoFragmentDoc}
${UserLinkFragmentDoc}
${TranslatedStringFragmentDoc}`;

/**
 * __useListMessagesQuery__
 *
 * To run a query within a React component, call `useListMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListMessagesQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useListMessagesQuery(baseOptions: Apollo.QueryHookOptions<ListMessagesQuery, ListMessagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListMessagesQuery, ListMessagesQueryVariables>(ListMessagesDocument, options);
      }
export function useListMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListMessagesQuery, ListMessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListMessagesQuery, ListMessagesQueryVariables>(ListMessagesDocument, options);
        }
export type ListMessagesQueryHookResult = ReturnType<typeof useListMessagesQuery>;
export type ListMessagesLazyQueryHookResult = ReturnType<typeof useListMessagesLazyQuery>;
export type ListMessagesQueryResult = Apollo.QueryResult<ListMessagesQuery, ListMessagesQueryVariables>;
export const SendMessageDocument = gql`
    mutation sendMessage($message: MessageInput!) {
  sendMessage(message: $message)
}
    `;
export type SendMessageMutationFn = Apollo.MutationFunction<SendMessageMutation, SendMessageMutationVariables>;

/**
 * __useSendMessageMutation__
 *
 * To run a mutation, you first call `useSendMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMessageMutation, { data, loading, error }] = useSendMessageMutation({
 *   variables: {
 *      message: // value for 'message'
 *   },
 * });
 */
export function useSendMessageMutation(baseOptions?: Apollo.MutationHookOptions<SendMessageMutation, SendMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendMessageMutation, SendMessageMutationVariables>(SendMessageDocument, options);
      }
export type SendMessageMutationHookResult = ReturnType<typeof useSendMessageMutation>;
export type SendMessageMutationResult = Apollo.MutationResult<SendMessageMutation>;
export type SendMessageMutationOptions = Apollo.BaseMutationOptions<SendMessageMutation, SendMessageMutationVariables>;
export const ListPurchasesDocument = gql`
    query listPurchases($userId: String!) {
  purchases(userId: $userId) {
    ...userPurchaseDetails
  }
}
    ${UserPurchaseDetailsFragmentDoc}`;

/**
 * __useListPurchasesQuery__
 *
 * To run a query within a React component, call `useListPurchasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPurchasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPurchasesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useListPurchasesQuery(baseOptions: Apollo.QueryHookOptions<ListPurchasesQuery, ListPurchasesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListPurchasesQuery, ListPurchasesQueryVariables>(ListPurchasesDocument, options);
      }
export function useListPurchasesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListPurchasesQuery, ListPurchasesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListPurchasesQuery, ListPurchasesQueryVariables>(ListPurchasesDocument, options);
        }
export type ListPurchasesQueryHookResult = ReturnType<typeof useListPurchasesQuery>;
export type ListPurchasesLazyQueryHookResult = ReturnType<typeof useListPurchasesLazyQuery>;
export type ListPurchasesQueryResult = Apollo.QueryResult<ListPurchasesQuery, ListPurchasesQueryVariables>;
export const ListUserMessagesDocument = gql`
    query listUserMessages($userId: String!, $pagination: Pagination!) {
  userMessages(userId: $userId, pagination: $pagination) {
    paginationInfo {
      ...fullPaginationInfo
    }
    items {
      id
      status
      message {
        sent
        text {
          ...TranslatedString
        }
        title {
          ...TranslatedString
        }
      }
    }
  }
}
    ${FullPaginationInfoFragmentDoc}
${TranslatedStringFragmentDoc}`;

/**
 * __useListUserMessagesQuery__
 *
 * To run a query within a React component, call `useListUserMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUserMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUserMessagesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useListUserMessagesQuery(baseOptions: Apollo.QueryHookOptions<ListUserMessagesQuery, ListUserMessagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListUserMessagesQuery, ListUserMessagesQueryVariables>(ListUserMessagesDocument, options);
      }
export function useListUserMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListUserMessagesQuery, ListUserMessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListUserMessagesQuery, ListUserMessagesQueryVariables>(ListUserMessagesDocument, options);
        }
export type ListUserMessagesQueryHookResult = ReturnType<typeof useListUserMessagesQuery>;
export type ListUserMessagesLazyQueryHookResult = ReturnType<typeof useListUserMessagesLazyQuery>;
export type ListUserMessagesQueryResult = Apollo.QueryResult<ListUserMessagesQuery, ListUserMessagesQueryVariables>;
export const SearchUsersDocument = gql`
    query searchUsers($search: String!, $rows: Int!) {
  listUsers(pagination: {cursor: null, rows: $rows}, search: $search) {
    items {
      ...userAutocompleteOption
    }
  }
}
    ${UserAutocompleteOptionFragmentDoc}`;

/**
 * __useSearchUsersQuery__
 *
 * To run a query within a React component, call `useSearchUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchUsersQuery({
 *   variables: {
 *      search: // value for 'search'
 *      rows: // value for 'rows'
 *   },
 * });
 */
export function useSearchUsersQuery(baseOptions: Apollo.QueryHookOptions<SearchUsersQuery, SearchUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchUsersQuery, SearchUsersQueryVariables>(SearchUsersDocument, options);
      }
export function useSearchUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchUsersQuery, SearchUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchUsersQuery, SearchUsersQueryVariables>(SearchUsersDocument, options);
        }
export type SearchUsersQueryHookResult = ReturnType<typeof useSearchUsersQuery>;
export type SearchUsersLazyQueryHookResult = ReturnType<typeof useSearchUsersLazyQuery>;
export type SearchUsersQueryResult = Apollo.QueryResult<SearchUsersQuery, SearchUsersQueryVariables>;
export const ListUsersDocument = gql`
    query listUsers($pagination: Pagination!, $search: String) {
  listUsers(pagination: $pagination, search: $search) {
    paginationInfo {
      ...fullPaginationInfo
    }
    items {
      ...userLink
      customerId
      email
      status
      registeredAt
    }
  }
}
    ${FullPaginationInfoFragmentDoc}
${UserLinkFragmentDoc}`;

/**
 * __useListUsersQuery__
 *
 * To run a query within a React component, call `useListUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUsersQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useListUsersQuery(baseOptions: Apollo.QueryHookOptions<ListUsersQuery, ListUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListUsersQuery, ListUsersQueryVariables>(ListUsersDocument, options);
      }
export function useListUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListUsersQuery, ListUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListUsersQuery, ListUsersQueryVariables>(ListUsersDocument, options);
        }
export type ListUsersQueryHookResult = ReturnType<typeof useListUsersQuery>;
export type ListUsersLazyQueryHookResult = ReturnType<typeof useListUsersLazyQuery>;
export type ListUsersQueryResult = Apollo.QueryResult<ListUsersQuery, ListUsersQueryVariables>;
export const GetUserDocument = gql`
    query getUser($id: String!) {
  user(id: $id) {
    ...userEnabled
    ...userBaseInfo
    ...userStatusInfo
  }
}
    ${UserEnabledFragmentDoc}
${UserBaseInfoFragmentDoc}
${UserStatusInfoFragmentDoc}`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const SetUserEnabledDocument = gql`
    mutation setUserEnabled($id: String!, $enabled: Boolean!, $comment: String!) {
  setUserEnabled(id: $id, enabled: $enabled, comment: $comment) {
    ...userEnabled
    status
    administratorComments {
      ...administratorComment
    }
  }
}
    ${UserEnabledFragmentDoc}
${AdministratorCommentFragmentDoc}`;
export type SetUserEnabledMutationFn = Apollo.MutationFunction<SetUserEnabledMutation, SetUserEnabledMutationVariables>;

/**
 * __useSetUserEnabledMutation__
 *
 * To run a mutation, you first call `useSetUserEnabledMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserEnabledMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserEnabledMutation, { data, loading, error }] = useSetUserEnabledMutation({
 *   variables: {
 *      id: // value for 'id'
 *      enabled: // value for 'enabled'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useSetUserEnabledMutation(baseOptions?: Apollo.MutationHookOptions<SetUserEnabledMutation, SetUserEnabledMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetUserEnabledMutation, SetUserEnabledMutationVariables>(SetUserEnabledDocument, options);
      }
export type SetUserEnabledMutationHookResult = ReturnType<typeof useSetUserEnabledMutation>;
export type SetUserEnabledMutationResult = Apollo.MutationResult<SetUserEnabledMutation>;
export type SetUserEnabledMutationOptions = Apollo.BaseMutationOptions<SetUserEnabledMutation, SetUserEnabledMutationVariables>;
export const AddCommentDocument = gql`
    mutation addComment($id: String!, $comment: String!) {
  addComment(id: $id, comment: $comment) {
    id
    administratorComments {
      ...administratorComment
    }
  }
}
    ${AdministratorCommentFragmentDoc}`;
export type AddCommentMutationFn = Apollo.MutationFunction<AddCommentMutation, AddCommentMutationVariables>;

/**
 * __useAddCommentMutation__
 *
 * To run a mutation, you first call `useAddCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCommentMutation, { data, loading, error }] = useAddCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useAddCommentMutation(baseOptions?: Apollo.MutationHookOptions<AddCommentMutation, AddCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCommentMutation, AddCommentMutationVariables>(AddCommentDocument, options);
      }
export type AddCommentMutationHookResult = ReturnType<typeof useAddCommentMutation>;
export type AddCommentMutationResult = Apollo.MutationResult<AddCommentMutation>;
export type AddCommentMutationOptions = Apollo.BaseMutationOptions<AddCommentMutation, AddCommentMutationVariables>;
export const ListValidationsDocument = gql`
    query listValidations($purchaseId: Int!) {
  validations(purchaseId: $purchaseId) {
    ...validationInfo
  }
}
    ${ValidationInfoFragmentDoc}`;

/**
 * __useListValidationsQuery__
 *
 * To run a query within a React component, call `useListValidationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListValidationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListValidationsQuery({
 *   variables: {
 *      purchaseId: // value for 'purchaseId'
 *   },
 * });
 */
export function useListValidationsQuery(baseOptions: Apollo.QueryHookOptions<ListValidationsQuery, ListValidationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListValidationsQuery, ListValidationsQueryVariables>(ListValidationsDocument, options);
      }
export function useListValidationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListValidationsQuery, ListValidationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListValidationsQuery, ListValidationsQueryVariables>(ListValidationsDocument, options);
        }
export type ListValidationsQueryHookResult = ReturnType<typeof useListValidationsQuery>;
export type ListValidationsLazyQueryHookResult = ReturnType<typeof useListValidationsLazyQuery>;
export type ListValidationsQueryResult = Apollo.QueryResult<ListValidationsQuery, ListValidationsQueryVariables>;