import TextField from '@mui/material/TextField';
import React, { useMemo } from 'react';
import { TimePicker } from '@mui/lab';
import { DateTime } from 'luxon';

interface Props {
    seconds: number;
    onChange: (seconds: number) => void;
}

const SecondsTimePicker = ({ seconds, onChange }: Props) => {
    const handleChange = (date: DateTime | null) => {
        if (date === null) {
            return;
        }
        const fixedDate = date.setZone('UTC', { keepLocalTime: true }).set({ year: 1970, month: 1, day: 1 });
        onChange(fixedDate.toSeconds());
    };

    const value = useMemo(() => DateTime.fromSeconds(seconds, { zone: 'UTC' }), [seconds]);

    return (
        <TimePicker
            value={value}
            onChange={handleChange}
            renderInput={params => <TextField {...params} variant="standard" sx={{ width: 88, pr: 1 }} />}
        />
    );
};

export default SecondsTimePicker;
