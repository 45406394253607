import { getConfig } from '@realcity/web-frame/lib/config';
import type { CustomViewState } from './scenes/PoiForm/LocationEditor';

interface AppConfig {
    BUG_REPORT_URL?: string;
    DOCUMENTS_URL?: string;
    DEFAULT_PAGE?: string;
    PREDEFINED_API_KEYS?: string;
    API_KEY_PICKER_ENABLED?: string;
    POI_TYPES?: string;
    POI_EDITOR_MAP_STYLE?: string;
    POI_EDITOR_MAP_DEFAULT_VIEW?: string;
}

interface PredefinedKey {
    apiKey: string;
    name: string;
}

export interface PoiType {
    name: string | { [language: string]: string | undefined };
    value: string;
}

const APP_CONFIG = getConfig<AppConfig>();
export const API_KEY_PICKER_ENABLED = APP_CONFIG.API_KEY_PICKER_ENABLED === 'true';
export const PREDEFINED_KEYS: readonly PredefinedKey[] = APP_CONFIG.PREDEFINED_API_KEYS ? JSON.parse(APP_CONFIG.PREDEFINED_API_KEYS) : [];
export const POI_TYPES: readonly PoiType[] = APP_CONFIG.POI_TYPES ? JSON.parse(APP_CONFIG.POI_TYPES) : [];
export const POI_EDITOR_MAP_DEFAULT_VIEW = APP_CONFIG.POI_EDITOR_MAP_DEFAULT_VIEW
    ? JSON.parse(APP_CONFIG.POI_EDITOR_MAP_DEFAULT_VIEW) as CustomViewState
    : null;

export default APP_CONFIG;
