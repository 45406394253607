/* tslint:disable */
/* eslint-disable */
/**
 * POI service belső
 * realCity POI service belső API.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ImportError,
    ImportErrorFromJSON,
    ImportErrorToJSON,
    ImportStatistics,
    ImportStatisticsFromJSON,
    ImportStatisticsToJSON,
    Poi,
    PoiFromJSON,
    PoiToJSON,
    PoiBatchUpdate,
    PoiBatchUpdateFromJSON,
    PoiBatchUpdateToJSON,
    PoiCreatableData,
    PoiCreatableDataFromJSON,
    PoiCreatableDataToJSON,
    PoiSortBy,
    PoiSortByFromJSON,
    PoiSortByToJSON,
    PoiUpdatableData,
    PoiUpdatableDataFromJSON,
    PoiUpdatableDataToJSON,
    Pois,
    PoisFromJSON,
    PoisToJSON,
    SortOrder,
    SortOrderFromJSON,
    SortOrderToJSON,
} from '../models';

export interface BatchUpdateRequest {
    poiBatchUpdate: PoiBatchUpdate;
}

export interface CreatePoiRequest {
    poiCreatableData: PoiCreatableData;
}

export interface DeletePoiRequest {
    id: string;
}

export interface ExportPoisRequest {
    type?: string;
}

export interface GetPoiRequest {
    id: string;
}

export interface GetPoisRequest {
    type?: string;
    sortBy?: PoiSortBy;
    sortOrder?: SortOrder;
    offset?: number;
    limit?: number;
}

export interface ImportPoisRequest {
    body: string;
}

export interface UpdatePoiRequest {
    id: string;
    poiUpdatableData: PoiUpdatableData;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Több POI mezőit frissíti
     */
    async batchUpdateRaw(requestParameters: BatchUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.poiBatchUpdate === null || requestParameters.poiBatchUpdate === undefined) {
            throw new runtime.RequiredError('poiBatchUpdate','Required parameter requestParameters.poiBatchUpdate was null or undefined when calling batchUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/pois`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PoiBatchUpdateToJSON(requestParameters.poiBatchUpdate),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Több POI mezőit frissíti
     */
    async batchUpdate(requestParameters: BatchUpdateRequest, initOverrides?: RequestInit): Promise<void> {
        await this.batchUpdateRaw(requestParameters, initOverrides);
    }

    /**
     * Létrehoz egy új POI-t.
     */
    async createPoiRaw(requestParameters: CreatePoiRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Poi>> {
        if (requestParameters.poiCreatableData === null || requestParameters.poiCreatableData === undefined) {
            throw new runtime.RequiredError('poiCreatableData','Required parameter requestParameters.poiCreatableData was null or undefined when calling createPoi.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/pois`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PoiCreatableDataToJSON(requestParameters.poiCreatableData),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PoiFromJSON(jsonValue));
    }

    /**
     * Létrehoz egy új POI-t.
     */
    async createPoi(requestParameters: CreatePoiRequest, initOverrides?: RequestInit): Promise<Poi> {
        const response = await this.createPoiRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Töröl egy POI-t
     */
    async deletePoiRaw(requestParameters: DeletePoiRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deletePoi.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/pois/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Töröl egy POI-t
     */
    async deletePoi(requestParameters: DeletePoiRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deletePoiRaw(requestParameters, initOverrides);
    }

    /**
     * A POI-kat CSV fájlba exportálja
     */
    async exportPoisRaw(requestParameters: ExportPoisRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/export`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * A POI-kat CSV fájlba exportálja
     */
    async exportPois(requestParameters: ExportPoisRequest, initOverrides?: RequestInit): Promise<string> {
        const response = await this.exportPoisRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Visszaad egy POI-t
     */
    async getPoiRaw(requestParameters: GetPoiRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Poi>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getPoi.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/pois/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PoiFromJSON(jsonValue));
    }

    /**
     * Visszaad egy POI-t
     */
    async getPoi(requestParameters: GetPoiRequest, initOverrides?: RequestInit): Promise<Poi> {
        const response = await this.getPoiRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * visszaadja a POI-k listáját
     */
    async getPoisRaw(requestParameters: GetPoisRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Pois>> {
        const queryParameters: any = {};

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sortBy'] = requestParameters.sortBy;
        }

        if (requestParameters.sortOrder !== undefined) {
            queryParameters['sortOrder'] = requestParameters.sortOrder;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/pois`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PoisFromJSON(jsonValue));
    }

    /**
     * visszaadja a POI-k listáját
     */
    async getPois(requestParameters: GetPoisRequest, initOverrides?: RequestInit): Promise<Pois> {
        const response = await this.getPoisRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * POI-k importálása CSV fájlból
     */
    async importPoisRaw(requestParameters: ImportPoisRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ImportStatistics>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling importPois.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'text/csv';

        const response = await this.request({
            path: `/import`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ImportStatisticsFromJSON(jsonValue));
    }

    /**
     * POI-k importálása CSV fájlból
     */
    async importPois(requestParameters: ImportPoisRequest, initOverrides?: RequestInit): Promise<ImportStatistics> {
        const response = await this.importPoisRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Frissíti egy POI adatait
     */
    async updatePoiRaw(requestParameters: UpdatePoiRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updatePoi.');
        }

        if (requestParameters.poiUpdatableData === null || requestParameters.poiUpdatableData === undefined) {
            throw new runtime.RequiredError('poiUpdatableData','Required parameter requestParameters.poiUpdatableData was null or undefined when calling updatePoi.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/pois/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PoiUpdatableDataToJSON(requestParameters.poiUpdatableData),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Frissíti egy POI adatait
     */
    async updatePoi(requestParameters: UpdatePoiRequest, initOverrides?: RequestInit): Promise<void> {
        await this.updatePoiRaw(requestParameters, initOverrides);
    }

}
