import React, { createContext, useCallback, useMemo, useRef, useState } from 'react';

type ReloadTableHandler = () => void;

interface PoisContextValue {
    selectedType: string | undefined;
    setSelectedType: (type: string | undefined) => void;
    reloadTable: () => void;
    setReloadTableHandler: (handler: ReloadTableHandler) => () => void;
}

export const PoisContext = createContext<PoisContextValue>(null as unknown as PoisContextValue);

export const PoisContextProvider: React.FC = ({ children }) => {
    const [selectedType, setSelectedType] = useState<string | undefined>(undefined);
    const reloadTableHandler = useRef<ReloadTableHandler | null>(null);

    const reloadTable = useCallback(() => {
        if (!reloadTableHandler.current) {
            return;
        }
        reloadTableHandler.current();
    }, []);

    const setReloadTableHandler = useCallback((handler: ReloadTableHandler) => {
        reloadTableHandler.current = handler;
        return () => {
            reloadTableHandler.current = null;
        };
    }, []);

    const value = useMemo((): PoisContextValue => ({
        selectedType,
        setSelectedType,
        reloadTable,
        setReloadTableHandler,
    }), [selectedType, reloadTable, setReloadTableHandler]);

    return (
        <PoisContext.Provider value={value}>
            {children}
        </PoisContext.Provider>
    );
};
